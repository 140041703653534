.abt-li-item {
    list-style-type: none;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff33;
    height: 85%;
    border-radius: 12px;
    border: 1px solid #ffffff;
    padding-left: 7px;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 7px;
    margin-left: 8px;
    margin-right: 8px;
    margin-bottom: 24px;
    text-decoration: none;
  }
  
  @media screen and (min-width: 768px) {
    .abt-li-item {
      width: 47%;
      padding-left: 24px;
      padding-top: 12px;
      padding-bottom: 12px;
      padding-right: 24px;
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 30px;
    }
  }
  
  .abt-li-name {
    text-align: center;
    color: #475569;
    font-family: 'Roboto';
    font-style: italic;
    font-size: 16px;
    margin-left: 4px;
  }

  .abt-li-detail{
    text-align: center;
    color: #7e858e;
    font-family: 'Roboto';
    font-size: 12px;
    margin-left: 4px;
  }
  
  @media screen and (min-width: 768px) {
    .abt-li-name {
      font-size: 32px;
      margin-left: 16px;
    }
    .abt-li-detail{
        font-size: 20px;
      margin-left: 16px;
    }
  }
  