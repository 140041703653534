.about-container{
  padding: 25px;
  background-image: linear-gradient(to bottom,#89c7ec,#fdfcfc);
  min-height: 50vh;
}

.about-list-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-left: 0px;
}

.about-main-head{
  font-family: "Roboto";
  color: #1e293b;
  font-size: 20px;
  margin: 8px;
}

  @media screen and (min-width: 768px) {
    .sm-img {
      display: none;
    }
    .lg-img {
      height: 600px;
      width: 800px;
      display: inline;
    }
    .about-main-head {
      font-size: 42px;
      margin:18px;
    }
  }
  