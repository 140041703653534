  .home-inner-card{
    width: 80%;
    align-self: center;
    padding: 20px;
    margin-top: 15vh;
  }

  .home-head{
    font-family: "Roboto";
    font-size: 32px;
    color: #30302e;
    font-weight: bold;
  }

  .home-desc{
    font-family: "Roboto";
    font-size: 17px;
    line-height: 1;
    color: #f1f1e9;
    font-weight: 400;
  }

  @media screen and (min-width: 768px) {
    .home-inner-card{
      width: 40%;
      align-self: flex-start;
    }
    .home-head{
      font-size: 42px;
      color: #ffffff;
    }
    .home-desc{
      font-size: 23px;
      line-height: 1.3;
      color: #fafafa;
    }
  }