  .contact-info {
    text-align: center;
  }
  
  .contact-info p {
    margin: 5px 0;
    line-height: 1.5;
  }
  
  .contact-info a {
    color: #959ead;
    font-family: "Roboto";
    font-size: 14px;
    font-weight: normal;
    text-decoration: none;
    margin-left: 5px;
  }
  
  .contact-info a:hover {
    text-decoration: underline;
  }
  


.footer-section {
    background-color: #0d2436;
    padding: 28px;
}

.footer-section-mail-id {
    color: #959ead;
    font-family: "Roboto";
    font-weight: bold;
    font-size: 16px;
    margin-top: 20px;

}

.footer-logo{
    height: 60px;
    width: 60px;
    margin: 7px;
}