
.link {
text-decoration: none;
color: black;
font-family: "Roboto";
}

.logo {
    height: 60px;
    width: 60px;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #f9fafa;
    color: #333;
    width: 100%;
    position: fixed;
    min-height: 15vh;
    border-bottom: 1px solid #dcdcdc;
  }
  
  .navbar-logo {
    font-size: 24px;
  }
  
  .navbar-links {
    display: flex;
    list-style-type: none;
    padding: 0;
  }
  
  .navbar-links li {
    margin-right: 20px;
    text-decoration: none;
    color: #333;
    font-family: "Roboto";
  }
  
  .hamburger-menu {
    display: none;
    flex-direction: column;
    cursor: pointer;
  }
  
  .bar {
    width: 25px;
    height: 3px;
    background-color: #333;
    margin: 3px 0;
  }
  
  @media screen and (max-width: 768px) {
    .navbar-links {
      display: none;
    }
  
    .hamburger-menu {
      display: flex;
    }
  
    .navbar-links.active {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 70px; /* Adjust as needed */
      left: 0;
      background-color: #f9fafa;
      width: 100%;
      padding: 10px;
    }
  
    .navbar-links.active li {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }
  