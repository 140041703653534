* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 90vh;
  padding: 20px;
  background-image: url('https://assets.ccbp.in/frontend/react-js/congrats-card-bg.png');
  background-size: cover;
}
